.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.src-components-Login-Login-module__BottomOptions--32ZM1 {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 0px;
}
.src-components-Login-Login-module__BottomOptions--32ZM1 * {
  color: gray;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
}
.src-components-Login-Login-module__BottomOptions--32ZM1 *:hover {
  color: #7ba932;
}
.src-components-Login-Login-module__Title--24jBc {
  margin: 25px;
  color: #9e9e9e;
}
.src-components-Login-Login-module__input--1Aybq {
  margin-bottom: 10px;
}
.src-components-Login-Login-module__form--39fH2 .ant-form-item-label {
  width: 100%;
  text-align: left;
  height: 14px;
  display: inline-flex;
  line-height: 14px;
}
.src-components-Login-Login-module__form--39fH2 .ant-form-explain {
  color: #f5222d;
  width: 100%;
  text-align: left;
  height: 10px;
  display: flex;
  font-size: 12px;
  line-height: 14px;
}


.LoginForm_Form__2tE7a{
	background: white;
	width: 100%;
	max-width: 400px;
	text-align: center;
	display: block;
	border-radius: 3px;
	z-index: 100;
}

.LoginForm_Form__2tE7a > img{
	width: 100px;
	margin: 50px auto 20px;
	display: block;
}

.LoginForm_Form__2tE7a > div{
	padding: 0px 30px 25px;
}

.LoginForm_Container__12TU5{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
	z-index: 999;
    position: relative;
}
.FullBackground_Background__2IvWh{
	position: fixed;
	z-index: 0;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: radial-gradient(circle at 100% 0%, #7ba932, #01594d);
	z-index: 999;
}
.ForgotPasswordDialog_Description__20EK5{
	margin: 0px 0px 20px;
}
.ForgotPasswordDialog_ButtonContainer__2gk3B{
	text-align: right;
}
.Input_error__3FZjh input, .Input_error__3FZjh textarea{
	border: 1px solid var(--error-color);
}
.Input_error__3FZjh p{
	color: var(--error-color);
    margin: 0px;
    font-size: 11px !important;
    text-align: left;
}
.Input_label__j7jgv{
    margin: 0px;
    font-size: 11px !important;
    text-align: left;
    color: var(--aic-secondary);
    font-weight: 500;
}
.src-components-AntMenu-AntMenu-module__container--2jSUy {
  position: relative;
  top: -60px;
}
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu-submenu-title,
.src-components-AntMenu-AntMenu-module__container--2jSUy .ant-menu-item {
  text-align: left;
  padding-left: 60px;
}
.src-components-AntMenu-AntMenu-module__imgContainer--1ET5b {
  height: 60px;
}
.src-components-AntMenu-AntMenu-module__imgContainer--1ET5b .ant-btn {
  left: 18px;
}
.src-components-AntMenu-AntMenu-module__Logo--1hxpG {
  height: 60px;
  width: 232px;
  padding: 13px;
  top: 5px;
  position: relative;
}
.src-components-AntMenu-AntMenu-module__AltLogo--GH2He {
  position: relative;
  left: 45px;
  height: 50px;
  padding: 10px;
  display: none;
}
.src-components-AntMenu-AntMenu-module__addApp--2soku {
  font-weight: 500;
}
.src-components-AntMenu-AntMenu-module__drawerMenu--2yzfO {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
}
.src-components-AntMenu-AntMenu-module__drawerMenu--2yzfO ul {
  height: 100%;
}
.src-components-AntMenu-AntMenu-module__drawerLogo--26f5s {
  width: 170px;
  display: block;
}
.src-components-AntMenu-AntMenu-module__menuIcon--3bq1P {
  position: fixed;
  width: 36px;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  left: 7px;
  top: 7px;
  font-size: 30px;
  visibility: hidden;
  opacity: 0;
}
.src-components-AntMenu-AntMenu-module__menuIcon--3bq1P > * {
  color: white;
}
.src-components-AntMenu-AntMenu-module__sideMenu--Ko4A7 > ul {
  height: calc(100vh - 50px);
}
@media (max-width: 800px) {
  .src-components-AntMenu-AntMenu-module__sideMenu--Ko4A7 {
    display: none;
  }
  .src-components-AntMenu-AntMenu-module__menuIcon--3bq1P {
    visibility: visible;
    opacity: 1;
  }
  .src-components-AntMenu-AntMenu-module__container--2jSUy {
    width: 100vw;
    overflow: auto;
    position: fixed;
    left: 0px;
    top: 60px;
    z-index: 1000;
  }
  .src-components-AntMenu-AntMenu-module__imgContainer--1ET5b {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }
  .src-components-AntMenu-AntMenu-module__Logo--1hxpG {
    display: none;
  }
  .src-components-AntMenu-AntMenu-module__AltLogo--GH2He {
    display: block;
  }
}

.src-components-Content-Content-module__Container--GquK2 {
  display: flex;
  height: calc(100vh - 50px);
}
.src-components-Content-Content-module__content--2nMiU {
  display: block;
  overflow: auto;
  text-align: center;
  padding: 20px 28px 0px;
  height: 100%;
  width: 100%;
  position: relative;
  background: #f5f5f5;
}
.src-components-Content-Content-module__children--2t818 {
  padding: 50px calc(50% - 600px) 40px;
  height: 100%;
  overflow: auto;
}
.src-components-Content-Content-module__children--2t818 > div {
  overflow: hidden;
}
.src-components-Content-Content-module__LargeSize--1f-_S {
  width: calc(100% - 90px);
}
.src-components-Content-Content-module__TopMenu--2g035 {
  height: 50px;
  width: 100%;
  background: #8dbe40;
}
.src-components-Content-Content-module__Logo--3wPid {
  height: 60px;
  padding: 13px;
  display: block;
}
.src-components-Content-Content-module__AltLogo--2osno {
  height: 59px;
  padding: 13px;
  display: none;
}
.src-components-Content-Content-module__Chat--9MdEt {
  position: absolute;
  top: 20px;
  right: 150px;
}
.src-components-Content-Content-module__Settings--5vWuG {
  position: absolute;
  top: 20px;
  right: 70px;
}
.src-components-Content-Content-module__Call--1FROG {
  position: absolute;
  top: 20px;
  right: 120px;
}
.src-components-Content-Content-module__AppSelect--1dGuU {
  position: relative;
  top: 15px;
  margin-right: 70px;
  float: right;
}
.src-components-Content-Content-module__AddApp--w_FQm {
  position: absolute;
  top: 20px;
  right: 120px;
}
.src-components-Content-Content-module__LogOut--2RMNy {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
}
.src-components-Content-Content-module__title--5ogec {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 5px 5px 5px 8px;
  align-items: center;
  z-index: 200;
  width: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  border-bottom: 1px solid #d6d6d6;
}
.src-components-Content-Content-module__title--5ogec h1 {
  margin: 0px;
  font-size: 20px;
  color: #8dbe3f !important;
  font-weight: 700;
  text-align: left;
  display: none;
}
.src-components-Content-Content-module__title--5ogec .src-components-Content-Content-module__regularTitle--9k0yL {
  display: block;
}
.src-components-Content-Content-module__responsiveTitle--eftpO {
  display: none;
  color: #8dbe3f !important;
}
.src-components-Content-Content-module__hasTabs--1_stT {
  padding-top: 60px;
}
@media (max-width: 800px) {
  .src-components-Content-Content-module__TopMenu--2g035 {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 999;
  }
  .src-components-Content-Content-module__Container--GquK2 {
    top: 50px;
    height: calc(100% - 50px);
    position: relative;
  }
  .src-components-Content-Content-module__content--2nMiU {
    width: 100%;
    padding: 12px;
    height: calc(100vh - 50px);
  }
  .src-components-Content-Content-module__AppSelect--1dGuU {
    top: 56px;
    background: #8dbe3f;
    position: fixed;
    width: 100vw;
    height: 43px;
  }
  .src-components-Content-Content-module__AppSelect--1dGuU > * {
    width: calc(100% - 20px) !important;
    top: 2px;
  }
  .src-components-Content-Content-module__title--5ogec {
    box-shadow: 0px 0px 7px -3px #3e3e3eab;
  }
  .src-components-Content-Content-module__title--5ogec h1 {
    display: block;
  }
  .src-components-Content-Content-module__regularTitle--9k0yL {
    display: none !important;
  }
  .src-components-Content-Content-module__responsiveTitle--eftpO {
    display: block;
  }
  .src-components-Content-Content-module__Logo--3wPid {
    display: none;
  }
  .src-components-Content-Content-module__AltLogo--2osno {
    display: block;
  }
}

.ConfirmationDialog_Title__17QdK{
	margin-bottom: 30px;
	text-align: center;
}
.ConfirmationDialog_ButtonContainer__1r71H{
	display: flex;
	justify-content: space-around;
}
.Dialog_Dialog__Hdp_X{
	position: fixed;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	left: 0;
	opacity: 0;
	visibility: hidden;
	top: 0;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.5s;
}
.Dialog_Dialog__Hdp_X.Dialog_Displayed__1GRxt{
	visibility: visible;
	opacity: 1;
}
.Dialog_Backdrop__1gRJ4{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 200;
	background: #00000044;
}
.Dialog_Container__t7BaN{
	top: 20px;
	width: 100%;
	max-width: 600px;
	background: white;
	border-radius: 3px;
	padding: 30px;
	z-index: 400;
	box-shadow: 0 0 40px 0 #00000052;
	transition: all 0.5s;
	max-height: calc(100vh - 60px);
}
.Dialog_Dialog__Hdp_X.Dialog_Displayed__1GRxt .Dialog_Container__t7BaN{
	top: 0px;
}
.Dialog_CloseButton__2s3Vs{
	position: absolute;
    width: 20px;
    height: 20px;
    padding: 5px;
    border: 2px solid #828282;
    border-radius: 50%;
    background: white;
    z-index: 9999;
    right: 10px;
    top: 10px;
    display: none;
}

@media (max-width: 770px){
	.Dialog_Container__t7BaN{
		width: 100% !important;
	    max-height: calc(100% - 60px);
	    overflow: auto;
	    border-radius: 0px;
	}
	.Dialog_CloseButton__2s3Vs{
		display: block;
	}
}
.Button_Button__1-aMf{
	background: #8dbf40;
    color: white;
    font-size: 18px;
    height: 50px;
    width: 100px;
    border: none;
    box-shadow: 0px 3px 20px 0px #67676733;
    transition: all 0.2s;
    font-weight: 700;
    outline: none;
    cursor: pointer;
}
.Button_Button__1-aMf:not(.Button_Loading__2vg9D):hover{
	background: #7da939;
}
.Button_White__YSQG4{
    background: white;
    color: #8dbf40;
    border:1px solid #efefef;
}
.Button_White__YSQG4:hover{
    background: whitesmoke !important;
}
.Button_Ghost__RuRNg{
    background: #ffffff00;
    border: 2px solid #8dbf40 !important;
    border-radius: 3px;
    color: #8dbf40;
    box-shadow: none;
}
.Button_Ghost__RuRNg:hover{
    background: #8dbf40 !important;
    color: white;
}
.Button_Payment__NJbhK{
    width: 245px;
    height: 35px;
    border-radius: 4px;
    box-shadow: none;
    background: gray;
    font-size: 14px;
    transition: all 0s;
}
.Button_Payment__NJbhK:hover{
    background: #9f9f9f !important;
}
.Button_Plain__l-RDm{
    border: none !important;
    height: auto !important;
    width: auto !important;
    color: gray;
    background: none !important;
    box-shadow: none !important;
    margin: 10px auto;
    text-decoration: underline;
    font-weight: 500;
}
.Preloader_Loader__1OSik,
.Preloader_Loader__1OSik:before,
.Preloader_Loader__1OSik:after {
  border-radius: 50%;
}
.Preloader_Loader__1OSik {
  color: #8dbd40;
  font-size: 11px;
  text-indent: -99999em;
  margin: 0 auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.Preloader_Loader__1OSik:before,
.Preloader_Loader__1OSik:after {
  position: absolute;
  content: '';
}
.Preloader_Loader__1OSik:before {
  width: 5.2em;
  height: 10.2em;
  background: white;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: Preloader_load2__3kxyz 1.33s infinite ease 1s;
          animation: Preloader_load2__3kxyz 1.33s infinite ease 1s;
}
.Preloader_Loader__1OSik:after {
  width: 5.2em;
  height: 10.2em;
  background: white;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: Preloader_load2__3kxyz 1.33s infinite ease;
          animation: Preloader_load2__3kxyz 1.33s infinite ease;
}
.Preloader_Inverse__3arXP {
  color:white;
}
.Preloader_Dark__-8rzp:before, .Preloader_Dark__-8rzp:after{
  background: black;
}
.Preloader_Inverse__3arXP:before, .Preloader_Inverse__3arXP:after{
  background: #8dbd40;
}
@-webkit-keyframes Preloader_load2__3kxyz {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Preloader_load2__3kxyz {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.BigLoader_Container__2jZAi{
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background: white;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999999;
}
.BigLoader_Container__2jZAi > div{
	width: 200px;
	text-align: center;
}
.BigLoader_Displayed__1IaT2{
	opacity: 1;
	visibility: visible;
}
.BigLoader_Container__2jZAi img{
	display: block;
	-webkit-filter: brightness(3);
	        filter: brightness(3);
	position: relative;
}
.BigLoader_Container__2jZAi h4{
	text-align: center;
	width: 100%;
	left: 0;
	color: #7f7f7f;
	top: calc(50% + 10px);
}
.BigLoader_Preloader__3yZvz{
	padding: 10px;
	position: relative;
}
.src-components-UI-AppSelect-AppSelect-module__SelectImg--1kcJG {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  top: -1px;
  position: relative;
}
.src-components-UI-AppSelect-AppSelect-module__select--12IfN {
  width: 300px;
}
@media (max-width: 800px) {
  .src-components-UI-AppSelect-AppSelect-module__select--12IfN {
    width: 100%;
  }
}

.src-components-OptimizationInformation-OptimizationInformation-module__container--Lf0rt .src-components-OptimizationInformation-OptimizationInformation-module__date--u-O9C {
  padding: 10px 10px !important;
}
.src-components-OptimizationInformation-OptimizationInformation-module__container--Lf0rt .src-components-OptimizationInformation-OptimizationInformation-module__controls--1N7Mw {
  text-align: left;
}
.src-components-OptimizationInformation-OptimizationInformation-module__container--Lf0rt .src-components-OptimizationInformation-OptimizationInformation-module__controls--1N7Mw > * {
  margin-right: 8px;
  margin-bottom: 12px;
}
.src-components-OptimizationInformation-OptimizationInformation-module__container--Lf0rt .ant-table-tbody textarea {
  padding: 10px 10px !important;
  background: transparent;
  border: none;
  display: block;
  height: 90px;
  text-align: left;
  border-left: 1px solid #e8e8e8 !important;
  border-radius: 0px;
  resize: none;
}
.src-components-OptimizationInformation-OptimizationInformation-module__container--Lf0rt .ant-table-tbody textarea:focus {
  background: whitesmoke;
  outline: none;
  box-shadow: 0px 0px 0px 1px gainsboro;
}
.src-components-OptimizationInformation-OptimizationInformation-module__container--Lf0rt .ant-table-tbody tr > td {
  padding: 0px 0px !important;
}

.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .src-components-PromotionResults-PromotionResults-module__date--2K6cG {
  padding: 10px 10px !important;
}
.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .src-components-PromotionResults-PromotionResults-module__controls--39Zbf {
  text-align: left;
}
.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .src-components-PromotionResults-PromotionResults-module__controls--39Zbf > * {
  margin-right: 8px;
  margin-bottom: 12px;
}
.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .ant-input-number {
  border: none;
  text-align: right;
  width: 100%;
  background: transparent;
}
.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .ant-input-number-input-wrap {
  padding-right: 20px;
  background: transparent;
}
.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .ant-table-tbody input {
  text-align: right;
  padding: 10px 10px !important;
  background: transparent;
}
.src-components-PromotionResults-PromotionResults-module__container--1Chp5 .ant-table-tbody input:focus {
  background: whitesmoke;
  outline: none;
  box-shadow: 0px 0px 0px 1px gainsboro;
}

.src-components-Attachments-Attachments-module__container--2dGEI .ant-card {
  margin-bottom: 20px;
}
.src-components-Attachments-Attachments-module__container--2dGEI .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.src-components-Attachments-Attachments-module__container--2dGEI .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.src-components-Attachments-Attachments-module__container--2dGEI .ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0;
}
.src-components-Attachments-Attachments-module__saveButton--3Szz2 {
  margin-bottom: 20px;
}

.src-components-Settings-Settings-module__container--3zOvZ {
  padding: 0 40px 40px;
}
.src-components-Settings-Settings-module__Avatar--1bdzb {
  width: 64px;
  height: 64px;
  line-height: 64px;
  font-size: 32px;
}
.src-components-Settings-Settings-module__roles--3lOLJ {
  text-align: left;
}
.src-components-Settings-Settings-module__roles--3lOLJ b {
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  font-family: roboto;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
html{
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar { 
  display: none; 
}
:root {
  --error-color: #ff868a;
  --aic-secondary: #5c7d2b;
}
.ant-btn {
  font-weight: 700;
}
::selection {
  background: #cccccc;
}
.ant-form-item-label {
  height: 30px;
  text-align: left;
  width: 100%;
}
.ant-form-item {
  margin-bottom: 10px;
}
.has-error {
  text-align: left;
}
.ant-tabs {
  margin-top: -20px !important;
}
.ant-tabs-bar {
  margin: 0px !important;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
  overflow: auto;
  transition: padding 0.1s;
  padding-top: 16px;
}
.ant-popover-inner-content {
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-inner-content p {
  max-width: 500px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
.ant-card-head {
  text-align: left;
  background: #fbfbfb;
}
.ant-table {
  background: white;
}
.ant-table.ant-table-default table {
  border-right: 1px solid gainsboro;
  border-left: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
}

